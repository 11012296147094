/*! PlainDraggable v2.5.14 (c) anseki https://anseki.github.io/plain-draggable/ */
var PlainDraggable = (function (t) {
    var e = {};
    function n(r) {
        if (e[r]) return e[r].exports;
        var o = (e[r] = { i: r, l: !1, exports: {} });
        return t[r].call(o.exports, o, o.exports, n), (o.l = !0), o.exports;
    }
    return (
        (n.m = t),
        (n.c = e),
        (n.d = function (t, e, r) {
            n.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: r });
        }),
        (n.r = function (t) {
            "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t, "__esModule", { value: !0 });
        }),
        (n.t = function (t, e) {
            if ((1 & e && (t = n(t)), 8 & e)) return t;
            if (4 & e && "object" == typeof t && t && t.__esModule) return t;
            var r = Object.create(null);
            if ((n.r(r), Object.defineProperty(r, "default", { enumerable: !0, value: t }), 2 & e && "string" != typeof t))
                for (var o in t)
                    n.d(
                        r,
                        o,
                        function (e) {
                            return t[e];
                        }.bind(null, o)
                    );
            return r;
        }),
        (n.n = function (t) {
            var e =
                t && t.__esModule
                    ? function () {
                          return t.default;
                      }
                    : function () {
                          return t;
                      };
            return n.d(e, "a", e), e;
        }),
        (n.o = function (t, e) {
            return Object.prototype.hasOwnProperty.call(t, e);
        }),
        (n.p = ""),
        n((n.s = 0))
    );
})([
    function (t, e, n) {
        "use strict";
        n.r(e);
        var r,
            o = [],
            i =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (t) {
                    return setTimeout(t, 1e3 / 60);
                },
            a =
                window.cancelAnimationFrame ||
                window.mozCancelAnimationFrame ||
                window.webkitCancelAnimationFrame ||
                window.msCancelAnimationFrame ||
                function (t) {
                    return clearTimeout(t);
                },
            l = Date.now();
        function s() {
            var t, e;
            r && (a.call(window, r), (r = null)),
                o.forEach(function (e) {
                    var n;
                    (n = e.event) && ((e.event = null), e.listener(n), (t = !0));
                }),
                t ? ((l = Date.now()), (e = !0)) : Date.now() - l < 500 && (e = !0),
                e && (r = i.call(window, s));
        }
        function u(t) {
            var e = -1;
            return (
                o.some(function (n, r) {
                    return n.listener === t && ((e = r), !0);
                }),
                e
            );
        }
        var c = {
            add: function (t) {
                var e;
                return -1 === u(t)
                    ? (o.push((e = { listener: t })),
                      function (t) {
                          (e.event = t), r || s();
                      })
                    : null;
            },
            remove: function (t) {
                var e;
                (e = u(t)) > -1 && (o.splice(e, 1), !o.length && r && (a.call(window, r), (r = null)));
            },
        };
        function d(t, e) {
            for (var n = 0; n < e.length; n++) {
                var r = e[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), "value" in r && (r.writable = !0), Object.defineProperty(t, r.key, r);
            }
        }
        var f = !1;
        try {
            window.addEventListener(
                "test",
                null,
                Object.defineProperty({}, "passive", {
                    get: function () {
                        f = !0;
                    },
                })
            );
        } catch (t) {}
        function p(t, e, n, r) {
            t.addEventListener(e, n, f ? r : r.capture);
        }
        function h(t, e) {
            if (null != t && null != e) for (var n = 0; n < t.length; n++) if (t[n].identifier === e) return t[n];
            return null;
        }
        function m(t) {
            return t && "number" == typeof t.clientX && "number" == typeof t.clientY;
        }
        function g(t) {
            t.preventDefault();
        }
        var y = (function () {
            function t(e) {
                var n = this;
                !(function (t, e) {
                    if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
                })(this, t),
                    (this.startHandlers = {}),
                    (this.lastHandlerId = 0),
                    (this.curPointerClass = null),
                    (this.curTouchId = null),
                    (this.lastPointerXY = { clientX: 0, clientY: 0 }),
                    (this.lastTouchTime = 0),
                    (this.options = { preventDefault: !0, stopPropagation: !0 }),
                    e &&
                        ["preventDefault", "stopPropagation"].forEach(function (t) {
                            "boolean" == typeof e[t] && (n.options[t] = e[t]);
                        });
            }
            var e, n, r;
            return (
                (e = t),
                (r = [
                    {
                        key: "addEventListenerWithOptions",
                        get: function () {
                            return p;
                        },
                    },
                ]),
                (n = [
                    {
                        key: "regStartHandler",
                        value: function (t) {
                            var e = this;
                            return (
                                (e.startHandlers[++e.lastHandlerId] = function (n) {
                                    var r,
                                        o,
                                        i = "mousedown" === n.type ? "mouse" : "touch",
                                        a = Date.now();
                                    if ("touch" === i) (e.lastTouchTime = a), (r = n.changedTouches[0]), (o = n.changedTouches[0].identifier);
                                    else {
                                        if (a - e.lastTouchTime < 400) return;
                                        r = n;
                                    }
                                    if (!m(r)) throw new Error("No clientX/clientY");
                                    e.curPointerClass && e.cancel(),
                                        t.call(e, r) &&
                                            ((e.curPointerClass = i),
                                            (e.curTouchId = "touch" === i ? o : null),
                                            (e.lastPointerXY.clientX = r.clientX),
                                            (e.lastPointerXY.clientY = r.clientY),
                                            e.options.preventDefault && n.preventDefault(),
                                            e.options.stopPropagation && n.stopPropagation());
                                }),
                                e.lastHandlerId
                            );
                        },
                    },
                    {
                        key: "unregStartHandler",
                        value: function (t) {
                            delete this.startHandlers[t];
                        },
                    },
                    {
                        key: "addStartHandler",
                        value: function (t, e) {
                            if (!this.startHandlers[e]) throw new Error("Invalid handlerId: ".concat(e));
                            return p(t, "mousedown", this.startHandlers[e], { capture: !1, passive: !1 }), p(t, "touchstart", this.startHandlers[e], { capture: !1, passive: !1 }), p(t, "dragstart", g, { capture: !1, passive: !1 }), e;
                        },
                    },
                    {
                        key: "removeStartHandler",
                        value: function (t, e) {
                            if (!this.startHandlers[e]) throw new Error("Invalid handlerId: ".concat(e));
                            return t.removeEventListener("mousedown", this.startHandlers[e], !1), t.removeEventListener("touchstart", this.startHandlers[e], !1), t.removeEventListener("dragstart", g, !1), e;
                        },
                    },
                    {
                        key: "addMoveHandler",
                        value: function (t, e) {
                            var n = this,
                                r = c.add(function (t) {
                                    var e = "mousemove" === t.type ? "mouse" : "touch";
                                    if (("touch" === e && (n.lastTouchTime = Date.now()), e === n.curPointerClass)) {
                                        var r = "touch" === e ? h(t.changedTouches, n.curTouchId) : t;
                                        m(r) &&
                                            ((r.clientX === n.lastPointerXY.clientX && r.clientY === n.lastPointerXY.clientY) || n.move(r), n.options.preventDefault && t.preventDefault(), n.options.stopPropagation && t.stopPropagation());
                                    }
                                });
                            p(t, "mousemove", r, { capture: !1, passive: !1 }), p(t, "touchmove", r, { capture: !1, passive: !1 }), (n.curMoveHandler = e);
                        },
                    },
                    {
                        key: "move",
                        value: function (t) {
                            m(t) && ((this.lastPointerXY.clientX = t.clientX), (this.lastPointerXY.clientY = t.clientY)), this.curMoveHandler && this.curMoveHandler(this.lastPointerXY);
                        },
                    },
                    {
                        key: "addEndHandler",
                        value: function (t, e) {
                            var n = this;
                            function r(t) {
                                var e = "mouseup" === t.type ? "mouse" : "touch";
                                if (("touch" === e && (n.lastTouchTime = Date.now()), e === n.curPointerClass)) {
                                    var r = "touch" === e ? h(t.changedTouches, n.curTouchId) || (h(t.touches, n.curTouchId) ? null : {}) : t;
                                    r && (n.end(r), n.options.preventDefault && t.preventDefault(), n.options.stopPropagation && t.stopPropagation());
                                }
                            }
                            p(t, "mouseup", r, { capture: !1, passive: !1 }), p(t, "touchend", r, { capture: !1, passive: !1 }), (n.curEndHandler = e);
                        },
                    },
                    {
                        key: "end",
                        value: function (t) {
                            m(t) && ((this.lastPointerXY.clientX = t.clientX), (this.lastPointerXY.clientY = t.clientY)), this.curEndHandler && this.curEndHandler(this.lastPointerXY), (this.curPointerClass = this.curTouchId = null);
                        },
                    },
                    {
                        key: "addCancelHandler",
                        value: function (t, e) {
                            var n = this;
                            p(
                                t,
                                "touchcancel",
                                function (t) {
                                    (n.lastTouchTime = Date.now()), null != n.curPointerClass && (h(t.changedTouches, n.curTouchId) || !h(t.touches, n.curTouchId)) && n.cancel();
                                },
                                { capture: !1, passive: !1 }
                            ),
                                (n.curCancelHandler = e);
                        },
                    },
                    {
                        key: "cancel",
                        value: function () {
                            this.curCancelHandler && this.curCancelHandler(), (this.curPointerClass = this.curTouchId = null);
                        },
                    },
                ]) && d(e.prototype, n),
                r && d(e, r),
                t
            );
        })();
        function v(t) {
            return t.substr(0, 1).toUpperCase() + t.substr(1);
        }
        var x,
            w,
            b,
            S,
            E = ["webkit", "moz", "ms", "o"],
            T = E.reduce(function (t, e) {
                return t.push(e), t.push(v(e)), t;
            }, []),
            B = E.map(function (t) {
                return "-".concat(t, "-");
            }),
            C = function () {
                return (S = S || document.createElement("div").style);
            },
            O =
                ((w = new RegExp("^(?:" + E.join("|") + ")(.)", "i")),
                (b = /[A-Z]/),
                function (t) {
                    return "float" ===
                        (t = (t + "")
                            .replace(/\s/g, "")
                            .replace(/-([\da-z])/gi, function (t, e) {
                                return e.toUpperCase();
                            })
                            .replace(w, function (t, e) {
                                return b.test(e) ? e.toLowerCase() : t;
                            })).toLowerCase()
                        ? "cssFloat"
                        : t;
                }),
            H =
                ((x = new RegExp("^(?:" + B.join("|") + ")", "i")),
                function (t) {
                    return (null != t ? t + "" : "").replace(/\s/g, "").replace(x, "");
                }),
            k = function (t, e) {
                var n = C();
                return (
                    (t = t.replace(/[A-Z]/g, function (t) {
                        return "-".concat(t.toLowerCase());
                    })),
                    n.setProperty(t, e),
                    null != n[t] && n.getPropertyValue(t) === e
                );
            },
            P = {},
            I = {};
        function _(t) {
            if ((t = O(t)) && null == P[t]) {
                var e = C();
                if (null != e[t]) P[t] = t;
                else {
                    var n = v(t);
                    T.some(function (r) {
                        var o = r + n;
                        return null != e[o] && ((P[t] = o), !0);
                    }) || (P[t] = !1);
                }
            }
            return P[t] || void 0;
        }
        var D = {
            getName: _,
            getValue: function (t, e) {
                var n;
                return (t = _(t))
                    ? ((I[t] = I[t] || {}),
                      (Array.isArray(e) ? e : [e]).some(function (e) {
                          return (
                              (e = H(e)),
                              null != I[t][e]
                                  ? !1 !== I[t][e] && ((n = I[t][e]), !0)
                                  : k(t, e)
                                  ? ((n = I[t][e] = e), !0)
                                  : !!B.some(function (r) {
                                        var o = r + e;
                                        return !!k(t, o) && ((n = I[t][e] = o), !0);
                                    }) || ((I[t][e] = !1), !1)
                          );
                      }),
                      "string" == typeof n ? n : void 0)
                    : n;
            },
        };
        function X(t) {
            return (t + "").trim();
        }
        function Y(t, e) {
            e.setAttribute("class", t.join(" "));
        }
        function L(t, e, n) {
            n.filter(function (e) {
                return !(!(e = X(e)) || -1 !== t.indexOf(e)) && (t.push(e), !0);
            }).length && Y(t, e);
        }
        function A(t, e, n) {
            n.filter(function (e) {
                var n;
                return !(!(e = X(e)) || -1 === (n = t.indexOf(e))) && (t.splice(n, 1), !0);
            }).length && Y(t, e);
        }
        function F(t) {
            return (
                (!F.ignoreNative && t.classList) ||
                ((e = (t.getAttribute("class") || "")
                    .trim()
                    .split(/\s+/)
                    .filter(function (t) {
                        return !!t;
                    })),
                (n = {
                    length: e.length,
                    item: function (t) {
                        return e[t];
                    },
                    contains: function (t) {
                        return -1 !== e.indexOf(X(t));
                    },
                    add: function () {
                        return L(e, t, Array.prototype.slice.call(arguments)), F.methodChain ? n : void 0;
                    },
                    remove: function () {
                        return A(e, t, Array.prototype.slice.call(arguments)), F.methodChain ? n : void 0;
                    },
                    toggle: function (n, r) {
                        return (function (t, e, n, r) {
                            var o = t.indexOf((n = X(n)));
                            return -1 !== o ? !!r || (t.splice(o, 1), Y(t, e), !1) : !1 !== r && (t.push(n), Y(t, e), !0);
                        })(e, t, n, r);
                    },
                    replace: function (r, o) {
                        return (
                            (function (t, e, n, r) {
                                var o;
                                (n = X(n)) && (r = X(r)) && n !== r && -1 !== (o = t.indexOf(n)) && (t.splice(o, 1), -1 === t.indexOf(r) && t.push(r), Y(t, e));
                            })(e, t, r, o),
                            F.methodChain ? n : void 0
                        );
                    },
                }))
            );
            var e, n;
        }
        F.methodChain = !0;
        var W = F;
        function j(t, e) {
            for (var n = 0; n < e.length; n++) {
                var r = e[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), "value" in r && (r.writable = !0), Object.defineProperty(t, r.key, r);
            }
        }
        function R(t) {
            return (R =
                "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
                    ? function (t) {
                          return typeof t;
                      }
                    : function (t) {
                          return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
                      })(t);
        }
        W.ignoreNative = !0;
        var M,
            z,
            N,
            V,
            G,
            q,
            U,
            Z,
            $,
            J,
            K,
            Q,
            tt,
            et = ["tl", "tr", "bl", "br"],
            nt = ["start", "end"],
            rt = ["inside", "outside"],
            ot = [40, 200, 1e3],
            it = [100, 40, 0],
            at = "-ms-scroll-limit" in document.documentElement.style && "-ms-ime-align" in document.documentElement.style && !window.navigator.msPointerEnabled,
            lt = !at && !!document.uniqueID,
            st = "MozAppearance" in document.documentElement.style,
            ut = !(at || st || !window.chrome || !window.CSS),
            ct = !(at || lt || st || ut || window.chrome || !("WebkitAppearance" in document.documentElement.style)),
            dt =
                ((M = {}.toString),
                (z = {}.hasOwnProperty.toString),
                (N = z.call(Object)),
                function (t) {
                    var e, n;
                    return t && "[object Object]" === M.call(t) && (!(e = Object.getPrototypeOf(t)) || ((n = e.hasOwnProperty("constructor") && e.constructor) && "function" == typeof n && z.call(n) === N));
                }),
            ft =
                Number.isFinite ||
                function (t) {
                    return "number" == typeof t && window.isFinite(t);
                },
            pt = {},
            ht = {},
            mt = new y(),
            gt = 0,
            yt = ct ? ["all-scroll", "move"] : ["grab", "all-scroll", "move"],
            vt = ct ? "move" : ["grabbing", "move"],
            xt = "plain-draggable",
            wt = "plain-draggable-dragging",
            bt = "plain-draggable-moving",
            St = {},
            Et =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (t) {
                    return setTimeout(t, 1e3 / 60);
                },
            Tt =
                window.cancelAnimationFrame ||
                window.mozCancelAnimationFrame ||
                window.webkitCancelAnimationFrame ||
                window.msCancelAnimationFrame ||
                function (t) {
                    return clearTimeout(t);
                };
        {
            var Bt,
                Ct,
                Ot,
                Ht = {};
            function kt() {
                var t = Date.now();
                ["x", "y"].forEach(function (e) {
                    var n = Ht[e];
                    if (n) {
                        var r = t - n.lastFrameTime,
                            o = Ct(Bt, e),
                            i = null != n.lastValue && Math.abs(n.lastValue - o) < 10 ? n.lastValue : o;
                        if (-1 === n.dir ? i > n.min : i < n.max) {
                            var a = i + n.speed * r * n.dir;
                            a < n.min ? (a = n.min) : a > n.max && (a = n.max), Ct(Bt, e, a), (n.lastValue = a);
                        }
                        n.lastFrameTime = t;
                    }
                });
            }
            function Pt() {
                Tt.call(window, Ot), kt(), (Ot = Et.call(window, Pt));
            }
            (St.move = function (t, e, n) {
                Tt.call(window, Ot), kt(), Bt === t && (e.x && Ht.x && (e.x.lastValue = Ht.x.lastValue), e.y && Ht.y && (e.y.lastValue = Ht.y.lastValue)), (Bt = t), (Ht = e), (Ct = n);
                var r = Date.now();
                ["x", "y"].forEach(function (t) {
                    var e = Ht[t];
                    e && (e.lastFrameTime = r);
                }),
                    (Ot = Et.call(window, Pt));
            }),
                (St.stop = function () {
                    Tt.call(window, Ot), kt(), (Ht = {}), (Bt = null);
                });
        }
        function It(t, e, n) {
            return null != n && ("x" === e ? t.scrollTo(n, t.pageYOffset) : t.scrollTo(t.pageXOffset, n)), "x" === e ? t.pageXOffset : t.pageYOffset;
        }
        function _t(t, e, n) {
            var r = "x" === e ? "scrollLeft" : "scrollTop";
            return null != n && (t[r] = n), t[r];
        }
        function Dt(t) {
            return t
                ? dt(t)
                    ? Object.keys(t).reduce(function (e, n) {
                          return (e[n] = Dt(t[n])), e;
                      }, {})
                    : Array.isArray(t)
                    ? t.map(Dt)
                    : t
                : t;
        }
        function Xt(t, e) {
            var n, r;
            return (
                R(t) !== R(e) ||
                (n = dt(t) ? "obj" : Array.isArray(t) ? "array" : "") != (dt(e) ? "obj" : Array.isArray(e) ? "array" : "") ||
                ("obj" === n
                    ? Xt((r = Object.keys(t).sort()), Object.keys(e).sort()) ||
                      r.some(function (n) {
                          return Xt(t[n], e[n]);
                      })
                    : "array" === n
                    ? t.length !== e.length ||
                      t.some(function (t, n) {
                          return Xt(t, e[n]);
                      })
                    : t !== e)
            );
        }
        function Yt(t) {
            return !(!t || t.nodeType !== Node.ELEMENT_NODE || "function" != typeof t.getBoundingClientRect || t.compareDocumentPosition(document) & Node.DOCUMENT_POSITION_DISCONNECTED);
        }
        function Lt(t) {
            if (!dt(t)) return null;
            var e;
            if (!ft((e = t.left)) && !ft((e = t.x))) return null;
            if (((t.left = t.x = e), !ft((e = t.top)) && !ft((e = t.y)))) return null;
            if (((t.top = t.y = e), ft(t.width) && t.width >= 0)) t.right = t.left + t.width;
            else {
                if (!(ft(t.right) && t.right >= t.left)) return null;
                t.width = t.right - t.left;
            }
            if (ft(t.height) && t.height >= 0) t.bottom = t.top + t.height;
            else {
                if (!(ft(t.bottom) && t.bottom >= t.top)) return null;
                t.height = t.bottom - t.top;
            }
            return t;
        }
        function At(t) {
            return ft(t)
                ? { value: t, isRatio: !1 }
                : "string" == typeof t
                ? (function (t) {
                      var e,
                          n,
                          r = /^(.+?)(%)?$/.exec(t);
                      return r && ft((e = parseFloat(r[1]))) ? { value: (n = !(!r[2] || !e)) ? e / 100 : e, isRatio: n } : null;
                  })(t.replace(/\s/g, ""))
                : null;
        }
        function Ft(t) {
            return t.isRatio ? "".concat(100 * t.value, "%") : t.value;
        }
        function Wt(t, e, n) {
            return "number" == typeof t ? t : e + t.value * (t.isRatio ? n : 1);
        }
        function jt(t) {
            if (!dt(t)) return null;
            var e;
            if (!(e = At(t.left)) && !(e = At(t.x))) return null;
            if (((t.left = t.x = e), !(e = At(t.top)) && !(e = At(t.y)))) return null;
            if (((t.top = t.y = e), (e = At(t.width)) && e.value >= 0)) (t.width = e), delete t.right;
            else {
                if (!(e = At(t.right))) return null;
                (t.right = e), delete t.width;
            }
            if ((e = At(t.height)) && e.value >= 0) (t.height = e), delete t.bottom;
            else {
                if (!(e = At(t.bottom))) return null;
                (t.bottom = e), delete t.height;
            }
            return t;
        }
        function Rt(t) {
            return Object.keys(t).reduce(function (e, n) {
                return (e[n] = Ft(t[n])), e;
            }, {});
        }
        function Mt(t, e) {
            var n = { left: "x", right: "x", x: "x", width: "x", top: "y", bottom: "y", y: "y", height: "y" },
                r = { x: e.left, y: e.top },
                o = { x: e.width, y: e.height };
            return Lt(
                Object.keys(t).reduce(function (e, i) {
                    return (e[i] = Wt(t[i], "width" === i || "height" === i ? 0 : r[n[i]], o[n[i]])), e;
                }, {})
            );
        }
        function zt(t, e) {
            var n = t.getBoundingClientRect(),
                r = { left: n.left, top: n.top, width: n.width, height: n.height };
            if (((r.left += window.pageXOffset), (r.top += window.pageYOffset), e)) {
                var o = window.getComputedStyle(t, ""),
                    i = parseFloat(o.borderTopWidth) || 0,
                    a = parseFloat(o.borderRightWidth) || 0,
                    l = parseFloat(o.borderBottomWidth) || 0,
                    s = parseFloat(o.borderLeftWidth) || 0;
                (r.left += s), (r.top += i), (r.width -= s + a), (r.height -= i + l);
            }
            return Lt(r);
        }
        function Nt(t, e) {
            null == U && (!1 !== yt && (U = D.getValue("cursor", yt)), null == U && (U = !1)), (t.style.cursor = !1 === U ? e : U);
        }
        function Vt(t) {
            null == Z && (!1 !== vt && (Z = D.getValue("cursor", vt)), null == Z && (Z = !1)), !1 !== Z && (t.style.cursor = Z);
        }
        function Gt(t, e, n) {
            var r = t.svgPoint;
            return (r.x = e), (r.y = n), r.matrixTransform(t.svgCtmElement.getScreenCTM().inverse());
        }
        function qt(t, e) {
            var n = t.elementBBox;
            if (e.left !== n.left || e.top !== n.top) {
                var r = t.htmlOffset;
                return (t.elementStyle[K] = "translate(".concat(e.left + r.left, "px, ").concat(e.top + r.top, "px)")), !0;
            }
            return !1;
        }
        function Ut(t, e) {
            var n = t.elementBBox,
                r = t.elementStyle,
                o = t.htmlOffset,
                i = !1;
            return e.left !== n.left && ((r.left = e.left + o.left + "px"), (i = !0)), e.top !== n.top && ((r.top = e.top + o.top + "px"), (i = !0)), i;
        }
        function Zt(t, e) {
            var n = t.elementBBox;
            if (e.left !== n.left || e.top !== n.top) {
                var r = t.svgOffset,
                    o = t.svgOriginBBox,
                    i = Gt(t, e.left - window.pageXOffset, e.top - window.pageYOffset);
                return t.svgTransform.setTranslate(i.x + r.x - o.x, i.y + r.y - o.y), !0;
            }
            return !1;
        }
        function $t(t, e, n) {
            var r = t.elementBBox;
            function o() {
                t.minLeft >= t.maxLeft ? (e.left = r.left) : e.left < t.minLeft ? (e.left = t.minLeft) : e.left > t.maxLeft && (e.left = t.maxLeft),
                    t.minTop >= t.maxTop ? (e.top = r.top) : e.top < t.minTop ? (e.top = t.minTop) : e.top > t.maxTop && (e.top = t.maxTop);
            }
            if ((o(), n)) {
                if (!1 === n(e)) return !1;
                o();
            }
            var i = t.moveElm(t, e);
            return i && (t.elementBBox = Lt({ left: e.left, top: e.top, width: r.width, height: r.height })), i;
        }
        function Jt(t) {
            var e = t.element,
                n = t.elementStyle,
                r = zt(e),
                o = ["display", "marginTop", "marginBottom", "width", "height"];
            o.unshift(K);
            var i = n[J];
            n[J] = "none";
            var a = zt(e);
            t.orgStyle
                ? o.forEach(function (e) {
                      (null != t.lastStyle[e] && n[e] !== t.lastStyle[e]) || (n[e] = t.orgStyle[e]);
                  })
                : ((t.orgStyle = o.reduce(function (t, e) {
                      return (t[e] = n[e] || ""), t;
                  }, {})),
                  (t.lastStyle = {}));
            var l = zt(e),
                s = window.getComputedStyle(e, "");
            "inline" === s.display &&
                ((n.display = "inline-block"),
                ["Top", "Bottom"].forEach(function (t) {
                    var e = parseFloat(s["padding".concat(t)]);
                    n["margin".concat(t)] = e ? "-".concat(e, "px") : "0";
                })),
                (n[K] = "translate(0, 0)");
            var u = zt(e),
                c = (t.htmlOffset = { left: u.left ? -u.left : 0, top: u.top ? -u.top : 0 });
            return (
                (n[K] = "translate(".concat(r.left + c.left, "px, ").concat(r.top + c.top, "px)")),
                ["width", "height"].forEach(function (r) {
                    u[r] !== l[r] && ((n[r] = l[r] + "px"), (u = zt(e))[r] !== l[r] && (n[r] = l[r] - (u[r] - l[r]) + "px")), (t.lastStyle[r] = n[r]);
                }),
                e.offsetWidth,
                (n[J] = i),
                (a.left === r.left && a.top === r.top) || (n[K] = "translate(".concat(a.left + c.left, "px, ").concat(a.top + c.top, "px)")),
                a
            );
        }
        function Kt(t) {
            var e = t.element,
                n = t.elementStyle,
                r = zt(e),
                o = ["position", "marginTop", "marginRight", "marginBottom", "marginLeft", "width", "height"],
                i = n[J];
            n[J] = "none";
            var a = zt(e);
            t.orgStyle
                ? o.forEach(function (e) {
                      (null != t.lastStyle[e] && n[e] !== t.lastStyle[e]) || (n[e] = t.orgStyle[e]);
                  })
                : ((t.orgStyle = o.reduce(function (t, e) {
                      return (t[e] = n[e] || ""), t;
                  }, {})),
                  (t.lastStyle = {}));
            var l = zt(e);
            (n.position = "absolute"), (n.left = n.top = n.margin = "0");
            var s = zt(e),
                u = (t.htmlOffset = { left: s.left ? -s.left : 0, top: s.top ? -s.top : 0 });
            return (
                (n.left = r.left + u.left + "px"),
                (n.top = r.top + u.top + "px"),
                ["width", "height"].forEach(function (r) {
                    s[r] !== l[r] && ((n[r] = l[r] + "px"), (s = zt(e))[r] !== l[r] && (n[r] = l[r] - (s[r] - l[r]) + "px")), (t.lastStyle[r] = n[r]);
                }),
                e.offsetWidth,
                (n[J] = i),
                (a.left === r.left && a.top === r.top) || ((n.left = a.left + u.left + "px"), (n.top = a.top + u.top + "px")),
                a
            );
        }
        function Qt(t) {
            var e = t.element,
                n = t.svgTransform,
                r = e.getBoundingClientRect(),
                o = zt(e);
            n.setTranslate(0, 0);
            var i = (t.svgOriginBBox = e.getBBox()),
                a = e.getBoundingClientRect(),
                l = Gt(t, a.left, a.top),
                s = (t.svgOffset = { x: i.x - l.x, y: i.y - l.y }),
                u = Gt(t, r.left, r.top);
            return n.setTranslate(u.x + s.x - i.x, u.y + s.y - i.y), o;
        }
        function te(t, e) {
            var n = zt(document.documentElement),
                r = (t.elementBBox = t.initElm(t)),
                o = (t.containmentBBox = t.containmentIsBBox ? Mt(t.options.containment, n) || n : zt(t.options.containment, !0));
            if (((t.minLeft = o.left), (t.maxLeft = o.right - r.width), (t.minTop = o.top), (t.maxTop = o.bottom - r.height), $t(t, { left: r.left, top: r.top }), t.parsedSnapTargets)) {
                var i = { x: r.width, y: r.height },
                    a = { x: t.minLeft, y: t.minTop },
                    l = { x: t.maxLeft, y: t.maxTop },
                    s = { left: "x", right: "x", x: "x", width: "x", xStart: "x", xEnd: "x", xStep: "x", top: "y", bottom: "y", y: "y", height: "y", yStart: "y", yEnd: "y", yStep: "y" },
                    u = t.parsedSnapTargets.reduce(function (t, e) {
                        var u,
                            c = "containment" === e.base ? o : n,
                            d = { x: c.left, y: c.top },
                            f = { x: c.width, y: c.height };
                        function p(n) {
                            if ((null == n.center && (n.center = e.center), null == n.xGravity && (n.xGravity = e.gravity), null == n.yGravity && (n.yGravity = e.gravity), null != n.x && null != n.y))
                                (n.x = Wt(n.x, d.x, f.x)),
                                    (n.y = Wt(n.y, d.y, f.y)),
                                    n.center && ((n.x -= i.x / 2), (n.y -= i.y / 2), (n.corners = ["tl"])),
                                    (n.corners || e.corners).forEach(function (e) {
                                        var r = n.x - ("tr" === e || "br" === e ? i.x : 0),
                                            o = n.y - ("bl" === e || "br" === e ? i.y : 0);
                                        if (r >= a.x && r <= l.x && o >= a.y && o <= l.y) {
                                            var s = { x: r, y: o },
                                                u = r - n.xGravity,
                                                c = r + n.xGravity,
                                                d = o - n.yGravity,
                                                f = o + n.yGravity;
                                            u > a.x && (s.gravityXStart = u), c < l.x && (s.gravityXEnd = c), d > a.y && (s.gravityYStart = d), f < l.y && (s.gravityYEnd = f), t.push(s);
                                        }
                                    });
                            else {
                                var r = null != n.x ? "x" : "y",
                                    o = "x" === r ? "y" : "x",
                                    s = "".concat(o, "Start"),
                                    u = "".concat(o, "End"),
                                    c = "".concat(r, "Gravity"),
                                    p = r.toUpperCase(),
                                    h = o.toUpperCase(),
                                    m = "gravity".concat(p, "Start"),
                                    g = "gravity".concat(p, "End"),
                                    y = "gravity".concat(h, "Start"),
                                    v = "gravity".concat(h, "End");
                                if (((n[r] = Wt(n[r], d[r], f[r])), (n[s] = Wt(n[s], d[o], f[o])), (n[u] = Wt(n[u], d[o], f[o]) - i[o]), n[s] > n[u] || n[s] > l[o] || n[u] < a[o])) return;
                                n.center && ((n[r] -= i[r] / 2), (n.sides = ["start"])),
                                    (n.sides || e.sides).forEach(function (e) {
                                        var d = n[r] - ("end" === e ? i[r] : 0);
                                        if (d >= a[r] && d <= l[r]) {
                                            var f = {},
                                                p = d - n[c],
                                                h = d + n[c];
                                            (f[r] = d), p > a[r] && (f[m] = p), h < l[r] && (f[g] = h), n[s] > a[o] && (f[y] = n[s]), n[u] < l[o] && (f[v] = n[u]), t.push(f);
                                        }
                                    });
                            }
                        }
                        if ((u = e.element ? zt(e.element) : null) || e.ppBBox)
                            e.ppBBox && (u = Mt(e.ppBBox, c)),
                                u &&
                                    e.edges.forEach(function (t) {
                                        var n = e.gravity,
                                            o = e.gravity;
                                        "outside" === t && ((n += r.width), (o += r.height));
                                        var i = u.left - n,
                                            a = u.right + n,
                                            l = u.top - o,
                                            s = u.bottom + o,
                                            c = "inside" === t ? "start" : "end";
                                        p({ xStart: i, xEnd: a, y: u.top, sides: [c], center: !1 }),
                                            p({ x: u.left, yStart: l, yEnd: s, sides: [c], center: !1 }),
                                            (c = "inside" === t ? "end" : "start"),
                                            p({ xStart: i, xEnd: a, y: u.bottom, sides: [c], center: !1 }),
                                            p({ x: u.right, yStart: l, yEnd: s, sides: [c], center: !1 });
                                    });
                        else {
                            var h = [
                                ["x", "y", "xStart", "xEnd", "xStep", "yStart", "yEnd", "yStep"].reduce(function (t, n) {
                                    return e[n] && (t[n] = Wt(e[n], "xStep" === n || "yStep" === n ? 0 : d[s[n]], f[s[n]])), t;
                                }, {}),
                            ];
                            ["x", "y"].forEach(function (t) {
                                var n = "".concat(t, "Start"),
                                    r = "".concat(t, "End"),
                                    o = "".concat(t, "Step"),
                                    i = "".concat(t, "Gravity");
                                h = h.reduce(function (a, l) {
                                    var s = l[n],
                                        u = l[r],
                                        c = l[o];
                                    if (null != s && null != u && s >= u) return a;
                                    if (null != c) {
                                        if (c < 2) return a;
                                        var d = c / 2;
                                        d = e.gravity > d ? d : null;
                                        for (var f = s; f <= u; f += c) {
                                            var p = Object.keys(l).reduce(function (t, e) {
                                                return e !== n && e !== r && e !== o && (t[e] = l[e]), t;
                                            }, {});
                                            (p[t] = f), (p[i] = d), a.push(p);
                                        }
                                    } else a.push(l);
                                    return a;
                                }, []);
                            }),
                                h.forEach(function (t) {
                                    p(t);
                                });
                        }
                        return t;
                    }, []);
                t.snapTargets = u.length ? u : null;
            }
            var c = {},
                d = t.options.autoScroll;
            if (d) {
                (c.isWindow = d.target === window), (c.target = d.target);
                var f = "scroll" === e,
                    p = (function (t, e, n) {
                        var r,
                            o,
                            i,
                            a,
                            l = {};
                        (a = e ? document.documentElement : t), (l.clientWidth = a.clientWidth), (l.clientHeight = a.clientHeight);
                        var s,
                            u,
                            c,
                            d = 0,
                            f = 0;
                        return (
                            n ||
                                (e
                                    ? ((s = It(t, "x")),
                                      (u = It(t, "y")),
                                      (r = getComputedStyle(document.documentElement, "")),
                                      (o = getComputedStyle(document.body, "")),
                                      (d = It(
                                          t,
                                          "x",
                                          document.documentElement.scrollWidth +
                                              l.clientWidth +
                                              ["marginLeft", "marginRight", "borderLeftWidth", "borderRightWidth", "paddingLeft", "paddingRight"].reduce(function (t, e) {
                                                  return t + (parseFloat(r[e]) || 0) + (parseFloat(o[e]) || 0);
                                              }, 0)
                                      )),
                                      (f = It(
                                          t,
                                          "y",
                                          document.documentElement.scrollHeight +
                                              l.clientHeight +
                                              ["marginTop", "marginBottom", "borderTopWidth", "borderBottomWidth", "paddingTop", "paddingBottom"].reduce(function (t, e) {
                                                  return t + (parseFloat(r[e]) || 0) + (parseFloat(o[e]) || 0);
                                              }, 0)
                                      )),
                                      It(t, "x", s),
                                      It(t, "y", u))
                                    : ((s = _t(t, "x")),
                                      (u = _t(t, "y")),
                                      (i = getComputedStyle(t, "")),
                                      (d = _t(
                                          t,
                                          "x",
                                          t.scrollWidth +
                                              l.clientWidth +
                                              ["marginLeft", "marginRight", "borderLeftWidth", "borderRightWidth", "paddingLeft", "paddingRight"].reduce(function (t, e) {
                                                  return t + (parseFloat(i[e]) || 0);
                                              }, 0)
                                      )),
                                      (f = _t(
                                          t,
                                          "y",
                                          t.scrollHeight +
                                              l.clientHeight +
                                              ["marginTop", "marginBottom", "borderTopWidth", "borderBottomWidth", "paddingTop", "paddingBottom"].reduce(function (t, e) {
                                                  return t + (parseFloat(i[e]) || 0);
                                              }, 0)
                                      )),
                                      _t(t, "x", s),
                                      _t(t, "y", u))),
                            (l.scrollWidth = l.clientWidth + d),
                            (l.scrollHeight = l.clientHeight + f),
                            e
                                ? (l.clientX = l.clientY = 0)
                                : ((c = t.getBoundingClientRect()), i || (i = getComputedStyle(t, "")), (l.clientX = c.left + (parseFloat(i.borderLeftWidth) || 0)), (l.clientY = c.top + (parseFloat(i.borderTopWidth) || 0))),
                            l
                        );
                    })(d.target, c.isWindow, f),
                    h = Lt({ left: p.clientX, top: p.clientY, width: p.clientWidth, height: p.clientHeight });
                f ? t.autoScroll && ((c.scrollWidth = t.autoScroll.scrollWidth), (c.scrollHeight = t.autoScroll.scrollHeight)) : ((c.scrollWidth = p.scrollWidth), (c.scrollHeight = p.scrollHeight)),
                    [
                        ["X", "Width", "left", "right"],
                        ["Y", "Height", "top", "bottom"],
                    ].forEach(function (t) {
                        var e = t[0],
                            n = t[1],
                            o = t[2],
                            i = t[3],
                            a = (c["scroll".concat(n)] || 0) - p["client".concat(n)],
                            l = d["min".concat(e)] || 0,
                            s = ft(d["max".concat(e)]) ? d["max".concat(e)] : a;
                        if (l < s && l < a) {
                            s > a && (s = a);
                            for (var u = [], f = r[n.toLowerCase()], m = d.sensitivity.length - 1; m >= 0; m--) {
                                var g = d.sensitivity[m],
                                    y = d.speed[m];
                                u.push({ dir: -1, speed: y, position: h[o] + g }), u.push({ dir: 1, speed: y, position: h[i] - g - f });
                            }
                            c[e.toLowerCase()] = { min: l, max: s, lines: u };
                        }
                    });
            }
            t.autoScroll = c.x || c.y ? c : null;
        }
        function ee(t) {
            St.stop(), Nt(t.options.handle, t.orgCursor), (q.style.cursor = $), !1 !== t.options.zIndex && (t.elementStyle.zIndex = t.orgZIndex), Q && (q.style[Q] = tt);
            var e = W(t.element);
            bt && e.remove(bt), wt && e.remove(wt), (V = null), mt.cancel(), t.onDragEnd && t.onDragEnd({ left: t.elementBBox.left, top: t.elementBBox.top });
        }
        function ne(t, e) {
            var n,
                r,
                o = t.options;
            e.containment &&
                (Yt(e.containment)
                    ? e.containment !== o.containment && ((o.containment = e.containment), (t.containmentIsBBox = !1), (n = !0))
                    : (r = jt(Dt(e.containment))) && Xt(r, o.containment) && ((o.containment = r), (t.containmentIsBBox = !0), (n = !0)));
            function i(t, e) {
                function n(t) {
                    return "string" == typeof t ? t.replace(/[, ]+/g, " ").trim().toLowerCase() : null;
                }
                ft(e.gravity) && e.gravity > 0 && (t.gravity = e.gravity);
                var r = n(e.corner);
                if (r) {
                    if ("all" !== r) {
                        var o = {},
                            i = r.split(/\s/).reduce(function (t, e) {
                                return (
                                    (e = "tl" === (e = e.trim().replace(/^(.).*?-(.).*$/, "$1$2")) || "lt" === e ? "tl" : "tr" === e || "rt" === e ? "tr" : "bl" === e || "lb" === e ? "bl" : "br" === e || "rb" === e ? "br" : null) &&
                                        !o[e] &&
                                        (t.push(e), (o[e] = !0)),
                                    t
                                );
                            }, []),
                            a = i.length;
                        r = a ? (4 === a ? "all" : i.join(" ")) : null;
                    }
                    r && (t.corner = r);
                }
                var l = n(e.side);
                l && ("start" === l || "end" === l || "both" === l ? (t.side = l) : ("start end" !== l && "end start" !== l) || (t.side = "both")), "boolean" == typeof e.center && (t.center = e.center);
                var s = n(e.edge);
                s && ("inside" === s || "outside" === s || "both" === s ? (t.edge = s) : ("inside outside" !== s && "outside inside" !== s) || (t.edge = "both"));
                var u = "string" == typeof e.base ? e.base.trim().toLowerCase() : null;
                return !u || ("containment" !== u && "document" !== u) || (t.base = u), t;
            }
            if (null != e.snap) {
                var a = dt(e.snap) && null != e.snap.targets ? e.snap : { targets: e.snap },
                    l = [],
                    s = i({ targets: l }, a);
                s.gravity || (s.gravity = 20), s.corner || (s.corner = "tl"), s.side || (s.side = "both"), "boolean" != typeof s.center && (s.center = !1), s.edge || (s.edge = "both"), s.base || (s.base = "containment");
                var u = (Array.isArray(a.targets) ? a.targets : [a.targets]).reduce(function (t, e) {
                    if (null == e) return t;
                    var n,
                        r = Yt(e),
                        o = jt(Dt(e)),
                        a = r || o ? { boundingBox: e } : dt(e) && null == e.start && null == e.end && null == e.step ? e : { x: e, y: e },
                        u = [],
                        c = {},
                        d = a.boundingBox;
                    if (r || Yt(d)) u.push({ element: d }), (c.boundingBox = d);
                    else if ((n = o || jt(Dt(d)))) u.push({ ppBBox: n }), (c.boundingBox = Rt(n));
                    else {
                        var f,
                            p = ["x", "y"].reduce(function (t, e) {
                                var n,
                                    r,
                                    o,
                                    i,
                                    l = a[e];
                                (n = At(l))
                                    ? ((t[e] = n), (c[e] = Ft(n)))
                                    : (dt(l) && ((r = At(l.start)), (o = At(l.end)), (i = At(l.step)), r && o && r.isRatio === o.isRatio && r.value >= o.value && (f = !0)),
                                      (r = t["".concat(e, "Start")] = r || { value: 0, isRatio: !1 }),
                                      (o = t["".concat(e, "End")] = o || { value: 1, isRatio: !0 }),
                                      (c[e] = { start: Ft(r), end: Ft(o) }),
                                      i && ((i.isRatio ? i.value > 0 : i.value >= 2) ? ((t["".concat(e, "Step")] = i), (c[e].step = Ft(i))) : (f = !0)));
                                return t;
                            }, {});
                        if (f) return t;
                        p.xStart && !p.xStep && p.yStart && !p.yStep
                            ? u.push({ xStart: p.xStart, xEnd: p.xEnd, y: p.yStart }, { xStart: p.xStart, xEnd: p.xEnd, y: p.yEnd }, { x: p.xStart, yStart: p.yStart, yEnd: p.yEnd }, { x: p.xEnd, yStart: p.yStart, yEnd: p.yEnd })
                            : u.push(p);
                    }
                    if (u.length) {
                        l.push(i(c, a));
                        var h = c.corner || s.corner,
                            m = c.side || s.side,
                            g = c.edge || s.edge,
                            y = {
                                gravity: c.gravity || s.gravity,
                                base: c.base || s.base,
                                center: "boolean" == typeof c.center ? c.center : s.center,
                                corners: "all" === h ? et : h.split(" "),
                                sides: "both" === m ? nt : [m],
                                edges: "both" === g ? rt : [g],
                            };
                        u.forEach(function (e) {
                            ["gravity", "corners", "sides", "center", "edges", "base"].forEach(function (t) {
                                e[t] = y[t];
                            }),
                                t.push(e);
                        });
                    }
                    return t;
                }, []);
                u.length && ((o.snap = s), Xt(u, t.parsedSnapTargets) && ((t.parsedSnapTargets = u), (n = !0)));
            } else e.hasOwnProperty("snap") && t.parsedSnapTargets && (o.snap = t.parsedSnapTargets = t.snapTargets = void 0);
            if (e.autoScroll) {
                var c = dt(e.autoScroll) ? e.autoScroll : { target: !0 === e.autoScroll ? window : e.autoScroll },
                    d = {};
                (d.target = Yt(c.target) ? c.target : window),
                    (d.speed = []),
                    (Array.isArray(c.speed) ? c.speed : [c.speed]).every(function (t, e) {
                        return !!(e <= 2 && ft(t)) && ((d.speed[e] = t), !0);
                    }),
                    d.speed.length || (d.speed = ot);
                var f = Array.isArray(c.sensitivity) ? c.sensitivity : [c.sensitivity];
                (d.sensitivity = d.speed.map(function (t, e) {
                    return ft(f[e]) ? f[e] : it[e];
                })),
                    ["X", "Y"].forEach(function (t) {
                        var e = "min".concat(t),
                            n = "max".concat(t);
                        ft(c[e]) && c[e] >= 0 && (d[e] = c[e]), ft(c[n]) && c[n] >= 0 && (!d[e] || c[n] >= d[e]) && (d[n] = c[n]);
                    }),
                    Xt(d, o.autoScroll) && ((o.autoScroll = d), (n = !0));
            } else e.hasOwnProperty("autoScroll") && (o.autoScroll && (n = !0), (o.autoScroll = void 0));
            if ((n && te(t), Yt(e.handle) && e.handle !== o.handle)) {
                o.handle && ((o.handle.style.cursor = t.orgCursor), Q && (o.handle.style[Q] = t.orgUserSelect), mt.removeStartHandler(o.handle, t.pointerEventHandlerId));
                var p = (o.handle = e.handle);
                (t.orgCursor = p.style.cursor), Nt(p, t.orgCursor), Q && ((t.orgUserSelect = p.style[Q]), (p.style[Q] = "none")), mt.addStartHandler(p, t.pointerEventHandlerId);
            }
            (ft(e.zIndex) || !1 === e.zIndex) && ((o.zIndex = e.zIndex), t === V && (t.elementStyle.zIndex = !1 === o.zIndex ? t.orgZIndex : o.zIndex));
            var h,
                m = { left: t.elementBBox.left, top: t.elementBBox.top };
            ft(e.left) && e.left !== m.left && ((m.left = e.left), (h = !0)),
                ft(e.top) && e.top !== m.top && ((m.top = e.top), (h = !0)),
                h && $t(t, m),
                ["onDrag", "onMove", "onDragStart", "onMoveStart", "onDragEnd"].forEach(function (n) {
                    "function" == typeof e[n] ? ((o[n] = e[n]), (t[n] = o[n].bind(t.ins))) : e.hasOwnProperty(n) && null == e[n] && (o[n] = t[n] = void 0);
                });
        }
        var re = (function () {
            function t(e, n) {
                !(function (t, e) {
                    if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
                })(this, t);
                var r = { ins: this, options: { zIndex: 9e3 }, disabled: !1 };
                if ((Object.defineProperty(this, "_id", { value: ++gt }), (r._id = this._id), (pt[this._id] = r), !Yt(e) || e === q)) throw new Error("This element is not accepted.");
                if (n) {
                    if (!dt(n)) throw new Error("Invalid options.");
                } else n = {};
                var o,
                    i,
                    a = !0;
                if (e instanceof SVGElement && (o = e.ownerSVGElement)) {
                    if (!e.getBBox) throw new Error("This element is not accepted. (SVGLocatable)");
                    if (!e.transform) throw new Error("This element is not accepted. (SVGAnimatedTransformList)");
                    (r.svgTransform = e.transform.baseVal.appendItem(o.createSVGTransform())), (r.svgPoint = o.createSVGPoint());
                    var l = e.nearestViewportElement;
                    (r.svgCtmElement = st ? l.appendChild(document.createElementNS(o.namespaceURI, "rect")) : l), (a = !1), (r.initElm = Qt), (r.moveElm = Zt);
                } else {
                    var s = D.getName("willChange");
                    s && (a = !1), !n.leftTop && K ? (s && (e.style[s] = "transform"), (r.initElm = Jt), (r.moveElm = qt)) : (s && (e.style[s] = "left, top"), (r.initElm = Kt), (r.moveElm = Ut));
                }
                ((r.element = (function (t, e) {
                    var n = t.style;
                    n.webkitTapHighlightColor = "transparent";
                    var r = D.getName("boxShadow"),
                        o = window.getComputedStyle(t, "")[r];
                    return (o && "none" !== o) || (n[r] = "0 0 1px transparent"), e && K && (n[K] = "translateZ(0)"), t;
                })(e, a)),
                (r.elementStyle = e.style),
                (r.orgZIndex = r.elementStyle.zIndex),
                xt && W(e).add(xt),
                (r.pointerEventHandlerId = mt.regStartHandler(function (t) {
                    return (function (t, e) {
                        return (
                            !t.disabled &&
                            (!t.onDragStart || !1 !== t.onDragStart(e)) &&
                            (V && ee(V),
                            Vt(t.options.handle),
                            (q.style.cursor = Z || window.getComputedStyle(t.options.handle, "").cursor),
                            !1 !== t.options.zIndex && (t.elementStyle.zIndex = t.options.zIndex),
                            Q && (q.style[Q] = "none"),
                            wt && W(t.element).add(wt),
                            (V = t),
                            (G = !1),
                            (ht.left = t.elementBBox.left - (e.clientX + window.pageXOffset)),
                            (ht.top = t.elementBBox.top - (e.clientY + window.pageYOffset)),
                            !0)
                        );
                    })(r, t);
                })),
                n.containment) || (n.containment = (i = e.parentNode) && Yt(i) ? i : q);
                n.handle || (n.handle = e), ne(r, n);
            }
            var e, n, r;
            return (
                (e = t),
                (r = [
                    {
                        key: "draggableCursor",
                        get: function () {
                            return yt;
                        },
                        set: function (t) {
                            yt !== t &&
                                ((yt = t),
                                (U = null),
                                Object.keys(pt).forEach(function (t) {
                                    var e = pt[t];
                                    e.disabled || (e === V && !1 !== Z) || (Nt(e.options.handle, e.orgCursor), e === V && ((q.style.cursor = $), (q.style.cursor = window.getComputedStyle(e.options.handle, "").cursor)));
                                }));
                        },
                    },
                    {
                        key: "draggingCursor",
                        get: function () {
                            return vt;
                        },
                        set: function (t) {
                            vt !== t &&
                                ((vt = t), (Z = null), V && (Vt(V.options.handle), !1 === Z && (Nt(V.options.handle, V.orgCursor), (q.style.cursor = $)), (q.style.cursor = Z || window.getComputedStyle(V.options.handle, "").cursor)));
                        },
                    },
                    {
                        key: "draggableClass",
                        get: function () {
                            return xt;
                        },
                        set: function (t) {
                            (t = t ? t + "" : void 0) !== xt &&
                                (Object.keys(pt).forEach(function (e) {
                                    var n = pt[e];
                                    if (!n.disabled) {
                                        var r = W(n.element);
                                        xt && r.remove(xt), t && r.add(t);
                                    }
                                }),
                                (xt = t));
                        },
                    },
                    {
                        key: "draggingClass",
                        get: function () {
                            return wt;
                        },
                        set: function (t) {
                            if ((t = t ? t + "" : void 0) !== wt) {
                                if (V) {
                                    var e = W(V.element);
                                    wt && e.remove(wt), t && e.add(t);
                                }
                                wt = t;
                            }
                        },
                    },
                    {
                        key: "movingClass",
                        get: function () {
                            return bt;
                        },
                        set: function (t) {
                            if ((t = t ? t + "" : void 0) !== bt) {
                                if (V && G) {
                                    var e = W(V.element);
                                    bt && e.remove(bt), t && e.add(t);
                                }
                                bt = t;
                            }
                        },
                    },
                ]),
                (n = [
                    {
                        key: "remove",
                        value: function () {
                            var t = pt[this._id];
                            (this.disabled = !0), mt.unregStartHandler(mt.removeStartHandler(t.options.handle, t.pointerEventHandlerId)), delete pt[this._id];
                        },
                    },
                    {
                        key: "setOptions",
                        value: function (t) {
                            return dt(t) && ne(pt[this._id], t), this;
                        },
                    },
                    {
                        key: "position",
                        value: function () {
                            return te(pt[this._id]), this;
                        },
                    },
                    {
                        key: "disabled",
                        get: function () {
                            return pt[this._id].disabled;
                        },
                        set: function (t) {
                            var e = pt[this._id];
                            (t = !!t) !== e.disabled &&
                                ((e.disabled = t),
                                e.disabled
                                    ? (e === V && ee(e), (e.options.handle.style.cursor = e.orgCursor), Q && (e.options.handle.style[Q] = e.orgUserSelect), xt && W(e.element).remove(xt))
                                    : (Nt(e.options.handle, e.orgCursor), Q && (e.options.handle.style[Q] = "none"), xt && W(e.element).add(xt)));
                        },
                    },
                    {
                        key: "element",
                        get: function () {
                            return pt[this._id].element;
                        },
                    },
                    {
                        key: "rect",
                        get: function () {
                            return Dt(pt[this._id].elementBBox);
                        },
                    },
                    {
                        key: "left",
                        get: function () {
                            return pt[this._id].elementBBox.left;
                        },
                        set: function (t) {
                            ne(pt[this._id], { left: t });
                        },
                    },
                    {
                        key: "top",
                        get: function () {
                            return pt[this._id].elementBBox.top;
                        },
                        set: function (t) {
                            ne(pt[this._id], { top: t });
                        },
                    },
                    {
                        key: "containment",
                        get: function () {
                            var t = pt[this._id];
                            return t.containmentIsBBox ? Rt(t.options.containment) : t.options.containment;
                        },
                        set: function (t) {
                            ne(pt[this._id], { containment: t });
                        },
                    },
                    {
                        key: "snap",
                        get: function () {
                            return Dt(pt[this._id].options.snap);
                        },
                        set: function (t) {
                            ne(pt[this._id], { snap: t });
                        },
                    },
                    {
                        key: "autoScroll",
                        get: function () {
                            return Dt(pt[this._id].options.autoScroll);
                        },
                        set: function (t) {
                            ne(pt[this._id], { autoScroll: t });
                        },
                    },
                    {
                        key: "handle",
                        get: function () {
                            return pt[this._id].options.handle;
                        },
                        set: function (t) {
                            ne(pt[this._id], { handle: t });
                        },
                    },
                    {
                        key: "zIndex",
                        get: function () {
                            return pt[this._id].options.zIndex;
                        },
                        set: function (t) {
                            ne(pt[this._id], { zIndex: t });
                        },
                    },
                    {
                        key: "onDrag",
                        get: function () {
                            return pt[this._id].options.onDrag;
                        },
                        set: function (t) {
                            ne(pt[this._id], { onDrag: t });
                        },
                    },
                    {
                        key: "onMove",
                        get: function () {
                            return pt[this._id].options.onMove;
                        },
                        set: function (t) {
                            ne(pt[this._id], { onMove: t });
                        },
                    },
                    {
                        key: "onDragStart",
                        get: function () {
                            return pt[this._id].options.onDragStart;
                        },
                        set: function (t) {
                            ne(pt[this._id], { onDragStart: t });
                        },
                    },
                    {
                        key: "onMoveStart",
                        get: function () {
                            return pt[this._id].options.onMoveStart;
                        },
                        set: function (t) {
                            ne(pt[this._id], { onMoveStart: t });
                        },
                    },
                    {
                        key: "onDragEnd",
                        get: function () {
                            return pt[this._id].options.onDragEnd;
                        },
                        set: function (t) {
                            ne(pt[this._id], { onDragEnd: t });
                        },
                    },
                ]) && j(e.prototype, n),
                r && j(e, r),
                t
            );
        })();
        mt.addMoveHandler(document, function (t) {
            if (V) {
                var e = { left: t.clientX + window.pageXOffset + ht.left, top: t.clientY + window.pageYOffset + ht.top };
                if (
                    $t(
                        V,
                        e,
                        V.snapTargets
                            ? function (t) {
                                  var e,
                                      n = V.snapTargets.length,
                                      r = !1,
                                      o = !1;
                                  for (e = 0; e < n && (!r || !o); e++) {
                                      var i = V.snapTargets[e];
                                      (null == i.gravityXStart || t.left >= i.gravityXStart) &&
                                          (null == i.gravityXEnd || t.left <= i.gravityXEnd) &&
                                          (null == i.gravityYStart || t.top >= i.gravityYStart) &&
                                          (null == i.gravityYEnd || t.top <= i.gravityYEnd) &&
                                          (r || null == i.x || ((t.left = i.x), (r = !0), (e = -1)), o || null == i.y || ((t.top = i.y), (o = !0), (e = -1)));
                                  }
                                  return (t.snapped = r || o), !V.onDrag || V.onDrag(t);
                              }
                            : V.onDrag
                    )
                ) {
                    var n = {},
                        r = V.autoScroll;
                    if (r) {
                        var o = { x: V.elementBBox.left - window.pageXOffset, y: V.elementBBox.top - window.pageYOffset };
                        ["x", "y"].forEach(function (t) {
                            if (r[t]) {
                                var e = r[t].min,
                                    i = r[t].max;
                                r[t].lines.some(function (r) {
                                    return (-1 === r.dir ? o[t] <= r.position : o[t] >= r.position) && ((n[t] = { dir: r.dir, speed: r.speed / 1e3, min: e, max: i }), !0);
                                });
                            }
                        });
                    }
                    n.x || n.y ? (St.move(r.target, n, r.isWindow ? It : _t), (e.autoScroll = !0)) : St.stop(), G || ((G = !0), bt && W(V.element).add(bt), V.onMoveStart && V.onMoveStart(e)), V.onMove && V.onMove(e);
                }
            }
        });
        {
            function oe() {
                V && ee(V);
            }
            mt.addEndHandler(document, oe), mt.addCancelHandler(document, oe);
        }
        {
            function ie() {
                (J = D.getName("transitionProperty")), (K = D.getName("transform")), ($ = q.style.cursor), (Q = D.getName("userSelect")) && (tt = q.style[Q]);
                var t,
                    e = {};
                function n(t, e) {
                    t.initElm && te(t, e);
                }
                var r = !1,
                    o = c.add(function (o) {
                        r ||
                            ((r = !0),
                            V && (n(V, o.type), mt.move(), (e[V._id] = !0)),
                            clearTimeout(t),
                            (t = setTimeout(function () {
                                var r;
                                (r = o.type),
                                    clearTimeout(t),
                                    Object.keys(pt).forEach(function (t) {
                                        e[t] || n(pt[t], r);
                                    }),
                                    (e = {});
                            }, 200)),
                            (r = !1));
                    });
                window.addEventListener("resize", o, !0), window.addEventListener("scroll", o, !0);
            }
            (q = document.body)
                ? ie()
                : document.addEventListener(
                      "DOMContentLoaded",
                      function () {
                          (q = document.body), ie();
                      },
                      !0
                  );
        }
        e.default = re;
    },
]).default;
